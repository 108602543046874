<template>
  <a-upload
    name="file"
    :showUploadList="false"
    accept=".jpg,.psd,.zip"
    :multiple="false"
    :before-upload="beforeUpload"
    :custom-request="handleRequest"
    :disabled="!selectKeys.length || selectKeys.length > 1"
  >
    <a-button
      v-if="!isTable"
      :disabled="!selectKeys.length || selectKeys.length > 1"
      :loading="loading"
      class="mr-3"
      icon="upload"
      >{{ btnText }}
    </a-button>
    <a-spin v-else :spinning="loading">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      <a>{{ btnText }}</a>
    </a-spin>
  </a-upload>
</template>

<script>
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import * as imageConversion from 'image-conversion'
import { changeDpiBlob } from '@/utils/changeDpi.js'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { getAction, postAction } from '@/api/manage'
import { simpleDebounce } from '@/utils/util'
import PSD from 'psd.js'
const JSZip = require('jszip')
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    btnText: {
      default: '上传',
      type: String
    },
    upType: {
      default: 'JPG',
      type: String
    },
    sizeList: {
      default: () => [],
      type: Array
    },
    showFileList: {
      default: () => [],
      type: Array
    },
    selectKeys: {
      default: () => [],
      type: Array
    },
    isTable: {
      default: false,
      type: Boolean
    },
    loadData: {
      type: Function,
      default: () => {}
    },
    saveUrl: {
      type: String,
      default: ''
    },
    btnType: {
      type: String,
      default: 'original'
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      fileList: [],
      defaultMaxHeight: 0,
      defaultMinHeight: 0,
      defaultWidth: 0,
      defaultType: 'JPG',
      /*       uploadFileList:[],
      topProgress:0,
      bottomProgress:0 */
      ossUploadDatas: {},
      upList: [],
      finishFileList: [],
      defaultDpi: 300,
      userInfo: {},
      psdPromise: [],
      psdFiles: []
    }
  },
  watch: {
    upList() {
      this.startProgress()
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
  },
  methods: {
    handleRequest() {
      return
    },
    startProgress() {
      this.$emit('startProgress', this.upList, this.finishFileList, this.upType)
    },
    changeProgress(isSuccess) {
      this.$emit('changeProgress', this.upList, this.finishFileList, this.upType, isSuccess ? false : true)
    },
    changeTransProgress() {
      this.$emit('changeTransProgress', this.upList, this.upType)
    },
    endProgress() {
      this.finishFileList = []
      this.upList = []
      this.$emit('endProgress', this.upType)
    },
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n)
      while (n--) {
        ia[n] = bytes.charCodeAt(n)
      }
      return new File([ia], fileName, { type: mime })
    },
    async beforeUpload(file, fileList) {
      this.$confirm({
        content: `确认替换？`,
        onOk: () => {
          if (!this.sizeList.length) {
            this.$message.error('未获取到默认尺寸，无法上传图片')
            return
          }
          if (this.btnType == 'original') {
            this.defaultMaxHeight = this.sizeList[0].size.psdMaxHeight
            this.defaultMinHeight = this.sizeList[0].size.psdMinHeight
            this.defaultWidth = this.sizeList[0].size.psdWidth
            this.defaultType = this.sizeList[0].size.psdFormat
            this.defaultDpi = this.sizeList[0].size.psdResolution || 300
          } else if (this.btnType == 'finish') {
            this.defaultMaxHeight = this.sizeList[0].size.jpgMaxHeight
            this.defaultMinHeight = this.sizeList[0].size.jpgMinHeight
            this.defaultWidth = this.sizeList[0].size.jpgWidth
            this.defaultType = this.sizeList[0].size.jpgFormat
            this.defaultDpi = this.sizeList[0].size.jpgResolution || 300
          }
/*           if (
            this.showFileList.length &&
            this.showFileList.some(item => item.fileLength == file.size && item.fileName == file.name)
          ) {
            this.$message.warning(file.name + '文件已存在，跳过上传')
            return false
          } */
          this.psdFiles = []
          this.psdPromise = []
          this.finishFileList = []
          fileList.forEach(item => {
            let type = item.name
              .split('.')
              .slice(-1)[0]
              .toLowerCase()
            if (type == 'psd' || type == 'PSD') {
              if (this.showFileList.every(i => i.fileLength != item.size || i.fileName != item.name)) {
                this.psdFiles.push(item)
              }
            }
          })
          this.loading = true
          this.handleUpload({ file })
        },
        onCancel: () => {
          return
        }
      })
    },
    async handleUpload(info) {
      let file = info.file
      let type = file.name
        .split('.')
        .slice(-1)[0]
        .toLowerCase()
      if (type == 'psd' || type == 'PSD') {
        this.fileList.push(file)
        this.upList.push(file)
        this.psdUpload(file)
      } else if (type == 'jpg' || type == 'JPG') {
        this.fileList.push(file)
        this.upList.push(file)
        this.jpgUpload(file)
      } else if (type == 'zip' || type == 'ZIP') {
        const zip = new JSZip()
        // 解压Zip压缩包，参数默认是二进制
        const zipData = await zip.loadAsync(file)
        this.upList = [...this.upList, ...Object.values(zipData.files).filter(item => !item.dir)]
        let zipFatherNameList = []
        for (let key in zipData.files) {
          if (zipData.files[key].dir) {
            zipFatherNameList.push(key)
          }
        }
        for (let key in zipData.files) {
          if (!zipData.files[key].dir) {
            // 判断是否是目录
            if (key.indexOf('psd') > -1 || key.indexOf('jpg') > -1) {
              this.fileList.push(zipData.files[key])
            }
            if (/\.(xlsx)$/.test(zipData.files[key].name)) {
              // 判断是否是xlsx文件
              let base = await zip.file(zipData.files[key].name).async('base64') // 以base64输出文本内容
              // Base64 转 File 对象
              const result = this.dataURLtoFile(base, zipData.files[key].name)
              /* this.fileList.push(result) */
              console.log(result, '最终解压后的File对象')
            } else {
              if (zipData.files[key].name.split('/').length > 2) {
                this.loading = false
                this.endProgress()
                this.$message.error('压缩文件存在二级目录，请修改层级后上传')
                return false
              }
              const promises = []
              let originName = zipData.files[key].name
              if (zipFatherNameList.some(item => zipData.files[key].name.indexOf(item) > -1)) {
                zipData.files[key].name = zipData.files[key].name.replace(
                  zipFatherNameList.find(item => zipData.files[key].name.indexOf(item) > -1),
                  ''
                )
              }
              let zipType = zipData.files[key].name
                .split('.')
                .slice(-1)[0]
                .toLowerCase()
              let promise = zip.file(originName).async('base64')
              promises.push(promise)
              Promise.all(promises).then(base => {
                const result = this.dataURLtoFile(base, zipData.files[key].name)
                if (
                  this.showFileList.length &&
                  this.showFileList.some(item => item.fileLength == result.size && item.fileName == result.name)
                ) {
                  this.finishFileList.push(result)
                  if (this.upList.length == this.finishFileList.length) {
                    this.loading = false
                    this.endProgress()
                  } else {
                    this.changeProgress()
                  }
                  this.$message.warning(result.name + '文件已存在，跳过上传')
                  return false
                }
                /* this.fileList.push(result) */
                this.ossUploadData = this.ossUploadDatas[result.name]
                this.ossFilePolicy[result.name] = this.ossUploadData
                if (zipType == 'psd' || zipType == 'PSD') {
                  this.psdZipUpload(result)
                } else if (zipType == 'jpg' || zipType == 'JPG') {
                  this.jpgUpload(result)
                }
              })
              /*               let zipType = zipData.files[key].name
                .split('.')
                .slice(-1)[0]
                .toLowerCase()
              if (zipType == 'psd' || zipType == 'PSD') {
                let base = await zip.file(zipData.files[key].name).async('base64') // 以base64输出文本内容
                // Base64 转 File 对象
                const result = this.dataURLtoFile(base, zipData.files[key].name)
                this.fileList.push(result)
                this.ossUploadData = this.ossUploadDatas[result.name]
                this.ossFilePolicy[result.name] = this.ossUploadData
                this.psdUpload(result)
              } else if (zipType == 'jpg' || zipType == 'JPG') {
                let base = await zip.file(zipData.files[key].name).async('base64') // 以base64输出文本内容
                // Base64 转 File 对象
                const result = this.dataURLtoFile(base, zipData.files[key].name)
                this.fileList.push(result)
                this.ossUploadData = this.ossUploadDatas[result.name]
                this.ossFilePolicy[result.name] = this.ossUploadData
                this.jpgUpload(result)
              } */
              console.log(zipData.files[key], '最终解压后的File对象')
            }
          }
        }
      }
    },
    dataURLtoFile(dataURL, fileName, fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      /**
       * 注意：【不同文件不同类型】，例如【图片类型】就是`data:image/png;base64,${dataURL}`.split(',')
       * 下面的是【excel文件(.xlsx尾缀)】的文件类型拼接，一个完整的 base64 应该
       * 是这样的,例如： data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
       */

      const arr = `data:${fileType};base64,${dataURL}`.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let blob = new File([u8arr], fileName, { type: mime })
      return blob
    },
    allUpload() {
      let that = this
      Promise.all(this.psdPromise.map(item => item.promise))
        .then(rest => {
          rest.forEach((res, index) => {
            changeDpiBlob(res, that.defaultDpi).then(blob => {
              let name = that.psdPromise[index].name
              let newFile = new File([blob], name, { type: 'contentType', lastModified: Date.now() })
              setTimeout(() => {
                that.upLoadEnd(newFile, that.upType, that.btnType, that.psdFiles.find(item => item.name == name).size)
              }, 0)
            })
          })
        })
        .catch(err => {
          that.fileList.splice(
            that.fileList.findIndex(item => item.name == file.name),
            1
          )
          if (!that.fileList.length) {
            that.loading = false
          }
          that.changeProgress(true)
          console.log('上传失败123')
          that.$message.error(file.name + '上传失败')
        })
    },
    jpgUpload(file) {
      let that = this
      let fileSize = file.size
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        let isLt2M = file.size / 1024 / 1024 > 4 // 判定图片大小是否小于4MB
        // 这里需要计算出图片的长宽
        let img = new Image()
        img.onload = function() {
          file.width = img.width // 获取到width放在了file属性上
          file.height = img.height // 获取到height放在了file属性上
          let valid = img.width > 680 // 图片宽度大于2000
          // console.log(11, file)
          if (!that.sizeList.length) {
            this.$message.error('未获取到默认尺寸，无法上传图片')
            return
          }
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          if (valid || isLt2M) {
            let compressNum = file.width - that.defaultWidth
            if (compressNum >= 100) {
              imageConversion
                .compressAccurately(file, {
                  size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
                  width: that.defaultWidth
                })
                .then(res => {
                  //修改dpi
                  changeDpiBlob(res, that.defaultDpi).then(blob => {
                    let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                    setTimeout(() => {
                      that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                    }, 0)
                  })

                  resolve(res)
                })
                .catch(err => {
                  that.fileList.splice(
                    that.fileList.findIndex(item => item.name == file.name),
                    1
                  )
                  if (!that.fileList.length) {
                    that.loading = false
                  }
                  that.changeProgress(true)
                  console.log('上传失败234')
                  that.$message.error(file.name + '上传失败')
                })
            } else if (compressNum < 100 && compressNum >= 0) {
              imageConversion
                .compressAccurately(file, {
                  size: ((file.size * 0.9) / 1000).toFixed(0),
                  width: that.defaultWidth
                })
                .then(res => {
                  //修改dpi
                  changeDpiBlob(res, that.defaultDpi).then(blob => {
                    let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                    setTimeout(() => {
                      that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                    }, 0)
                  })

                  resolve(res)
                })
                .catch(err => {
                  that.fileList.splice(
                    that.fileList.findIndex(item => item.name == file.name),
                    1
                  )
                  if (!that.fileList.length) {
                    that.loading = false
                  }
                  that.changeProgress(true)
                  console.log('上传失败456')
                  that.$message.error(file.name + '上传失败')
                })
            } else {
              that.$message.error(file.name + '文件宽度小于默认尺寸，无法上传图片')
              that.fileList.splice(
                that.fileList.findIndex(item => item.name == file.name),
                1
              )
              that.changeProgress(true)
              if (!that.fileList.length) {
                that.endProgress()
                that.loading = false
              }
              that.avatarLoading = false
              that.spinning = false
            }
          } else {
            that.$message.error(file.name + '文件宽度小于默认尺寸，无法上传图片')
            that.fileList.splice(
              that.fileList.findIndex(item => item.name == file.name),
              1
            )
            that.changeProgress(true)
            if (!that.fileList.length) {
              that.endProgress()
              that.loading = false
            }
            that.avatarLoading = false
            that.spinning = false
          }
        }
        // 需要把图片赋值
        img.src = _URL.createObjectURL(file)
      })
    },
    //psd转jpg并压缩修改宽度
    async psdUpload(psdFile) {
      let that = this
      let fileSize = psdFile.size
      var url = URL.createObjectURL(psdFile)
      const psd = await PSD.fromURL(url)
      // 图片不大于4m,宽度不大于2000
      let file = this.base64ToFile(psd.image.toBase64().replace('png', that.upType), psdFile.name)
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        // 这里需要计算出图片的长宽
        let img = new Image()
        img.onload = function() {
          file.width = img.width // 获取到width放在了file属性上
          file.height = img.height // 获取到height放在了file属性上
          // console.log(11, file)
          if (!that.sizeList.length) {
            this.$message.error('未获取到默认尺寸，无法上传图片')
            return
          }
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          let compressNum = file.width - that.defaultWidth
          if (compressNum >= 100) {
            const promise = imageConversion.compressAccurately(file, {
              size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
              width: that.defaultWidth
            })
            that.psdPromise.push({ promise, name: file.name })
            that.changeTransProgress(that.psdFiles, 'PSD')
            if (that.psdPromise.length == that.psdFiles.length) {
              that.allUpload()
            }

            /*               .then(res => {
                changeDpiBlob(res, that.defaultDpi).then(blob => {
                  let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                  setTimeout(() => {
                    that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                  }, 0)
                })
                resolve(res)
              })
              .catch(err => {
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                that.changeProgress()
                that.$message.error(file.name + '上传失败')
              }) */
          } else if (compressNum < 100 && compressNum >= 0) {
            const promise = imageConversion.compressAccurately(file, {
              size: ((file.size * 0.9) / 1000).toFixed(0),
              width: that.defaultWidth
            })
            that.psdPromise.push({ promise, name: file.name })
            that.changeTransProgress(that.psdFiles, 'PSD')
            if (that.psdPromise.length == that.psdFiles.length) {
              that.allUpload()
            }
          } else {
            that.$message.error(file.name + '文件宽度小于默认尺寸，无法上传图片')
            that.fileList.splice(
              that.fileList.findIndex(item => item.name == file.name),
              1
            )
            that.changeProgress(true)
            if (!that.fileList.length) {
              that.endProgress()
              that.loading = false
            }
            that.avatarLoading = false
            that.spinning = false
          }
        }
        // 需要把图片赋值
        img.src = _URL.createObjectURL(file)
      })
    },
    async psdZipUpload(psdFile) {
      let that = this
      let fileSize = psdFile.size
      var url = URL.createObjectURL(psdFile)
      const psd = await PSD.fromURL(url)
      // 图片不大于4m,宽度不大于2000
      let file = this.base64ToFile(psd.image.toBase64().replace('png', that.upType), psdFile.name)
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        // 这里需要计算出图片的长宽
        let img = new Image()
        img.onload = function() {
          file.width = img.width // 获取到width放在了file属性上
          file.height = img.height // 获取到height放在了file属性上
          // console.log(11, file)
          if (!that.sizeList.length) {
            this.$message.error('未获取到默认尺寸，无法上传图片')
            return
          }
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          let compressNum = file.width - that.defaultWidth
          if (compressNum >= 100) {
            imageConversion
              .compressAccurately(file, {
                size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
                width: that.defaultWidth
              })
              .then(res => {
                changeDpiBlob(res, that.defaultDpi).then(blob => {
                  let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                  setTimeout(() => {
                    that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                  }, 0)
                })
                resolve(res)
              })
              .catch(err => {
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                that.changeProgress(true)
                console.log('上传失败789')
                that.$message.error(file.name + '上传失败')
              })
          } else if (compressNum < 100 && compressNum >= 0) {
            imageConversion
              .compressAccurately(file, {
                size: ((file.size * 0.9) / 1000).toFixed(0),
                width: that.defaultWidth
              })
              .then(res => {
                changeDpiBlob(res, that.defaultDpi).then(blob => {
                  let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
                  setTimeout(() => {
                    that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                  }, 0)
                })
                /* that
                  .getBase64(res)
                  .then(rest => {
                    that.upLoadEnd(rest, that.defaultType, that.btnType)
                  })
                  .catch(err => {
                    //失败你做的事情
                  }) */
                resolve(res)
              })
              .catch(err => {
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                that.changeProgress(true)
                console.log('上传失败890')
                that.$message.error(file.name + '上传失败')
              })
          } else {
            that.$message.error(file.name + '文件宽度小于默认尺寸，无法上传图片')
            that.fileList.splice(
              that.fileList.findIndex(item => item.name == file.name),
              1
            )
            that.changeProgress(true)
            if (!that.fileList.length) {
              that.endProgress()
              that.loading = false
            }
            that.avatarLoading = false
            that.spinning = false
          }
        }
        // 需要把图片赋值
        img.src = _URL.createObjectURL(file)
      })
    },
    fileToBlob(file) {
      var reader = new FileReader()
      reader.readAsArrayBuffer(file)
      return (reader.onload = function(event) {
        let blob = new Blob([event.target.result], { type: file.type })
        return blob
      })
    },
    async upLoadEnd(file, type, btnType, fileSize) {
      this.changeTransProgress()
      let uploadPath = ''
      if (
        this.taskDetail.productionName &&
        this.taskDetail.chapterSequenceNo &&
        this.taskDetail.platformName &&
        this.taskDetail.taskName
      ) {
        uploadPath =
          this.taskDetail.platformName +
          '/' +
          this.taskDetail.productionName +
          '/' +
          this.taskDetail.chapterSequenceNo +
          '/' +
          this.taskDetail.taskName +
          '/' +
          this.getGuid()
      }
      if (!uploadPath) {
        uploadPath = this.getGuid()
      }
      console.log(uploadPath, '上传OSS平台作品阶段路径')
      this.tokenAxios({
        url: '/oss',
        method: 'get'
      })
        .then(async res => {
          let policy = {
            policy: res.data.policy,
            signature: res.data.signature,
            ossaccessKeyId: res.data.accessid,
            dir: res.data.dir,
            host: res.data.host,
            key: `${res.data.dir}glxt/scyg/psd/${this.userInfo.realname}/${uploadPath}/${file.name
              .replaceAll('+', '加')
              .replaceAll(' ', '')}`
          }
          this.ossUploadDatas[file.name] = policy
          this.ossFilePolicy[file.name] = policy
          let formData = new FormData()
          for (let key in this.ossUploadDatas[file.name]) {
            formData.append(key, this.ossUploadDatas[file.name][key])
          }
          formData.append('file', file)
          /*           let data = new FormData()
          let dpi = 0
          data.append('files', file)
          const rest = await postAction('/kt/translate/getDpi', data)
          if (rest.code == 200 && rest.data) {
            dpi = rest.data[0]
          } */
          let that = this
          postAction(policy.host, formData)
            .then(res => {
              console.log('成功OSS', policy.host, formData)
              let index = 0
              Object.assign(file, { status: 'done' })
              let params = this.getSaveFileParams(file)
              var read = new FileReader()
              read.readAsDataURL(file)
              read.onload = function(e) {
                var img = new Image()
                img.src = e.target.result
                if (!that.sizeList.length) {
                  this.$message.error('未获取到默认尺寸，无法上传图片')
                  return
                }
                img.onload = async function() {
                  if (that.defaultType.indexOf(type) == -1) {
                    that.$message.error(file.name + '格式错误')
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.endProgress()
                      that.loading = false
                    }
                    that.changeProgress()
                    that.avatarLoading = false
                    that.spinning = false
                    postAction('/oss/delete_oss_file', [params.filePath])
                    return
                  } else if (!this.height) {
                    that.$message.error(file.name + '图片加载失败')
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    that.changeProgress()
                    if (!that.fileList.length) {
                      that.endProgress()
                      that.loading = false
                    }
                    that.avatarLoading = false
                    that.spinning = false
                    postAction('/oss/delete_oss_file', [params.filePath])
                  } else if (this.height > that.defaultMaxHeight || this.height < that.defaultMinHeight) {
                    that.$message.error(file.name + '高度错误')
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    that.changeProgress()
                    if (!that.fileList.length) {
                      that.endProgress()
                      that.loading = false
                    }
                    that.avatarLoading = false
                    that.spinning = false
                    postAction('/oss/delete_oss_file', [params.filePath])
                    return
                  } else if (this.width != that.defaultWidth) {
                    that.$message.error(file.name + '宽度错误')
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    that.changeProgress()
                    if (!that.fileList.length) {
                      that.endProgress()
                      that.loading = false
                    }
                    that.avatarLoading = false
                    that.spinning = false
                    postAction('/oss/delete_oss_file', [params.filePath])
                    return
                  } /*  else if (dpi != size.size.psdResolution) {
                that.$message.error('分辨率错误')
                that.avatarLoading = false
                that.spinning = false
                return
              } */
                  // 默认按比例压缩
                  let fileType = file.name
                    .split('.')
                    .slice(-1)[0]
                    .toLowerCase()
                  let fileName = ''
                  if (fileType == 'psd' || fileType == 'PSD') {
                    fileName = file.name /* .split('.')[0] + '.jpg' */
                  } else {
                    fileName = file.name
                  }
                  let data = {
                    chapterId: that.taskDetail.chapterId,
                    replaceId: that.selectKeys[0],
                    dataList: [
                      {
                        fileName: file.name,
                        filePath: params.filePath,
                        fileType: type,
                        wasSuccess: true,
                        dpi: that.defaultDpi,
                        height: this.height,
                        width: this.width,
                        fileLength: fileSize,
                        lastModified: that.formatDate(file.lastModifiedDate)
                      }
                    ]
                  }
                  const res = await postAction(that.saveUrl, data)
                  try {
                    if (res.code == 200) {
                      that.fileList.splice(
                        that.fileList.findIndex(item => item.name == file.name),
                        1
                      )
                      that.loadData()
                      that.changeProgress()
                      that.finishFileList.push(file.name)
                      if (!that.fileList.length) {
                        that.loading = false
                        that.upList = []
                        that.endProgress()
                        that.$message.success('上传完成')
                      }
                    } else {
                      that.fileList.splice(
                        that.fileList.findIndex(item => item.name == file.name),
                        1
                      )
                      if (!that.fileList.length) {
                        that.loading = false
                      }
                      that.changeProgress(true)
                      console.log('上传失败901')
                      that.$message.error(file.name + '上传失败')
                    }
                  } catch (err) {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    that.changeProgress(true)
                    console.log('上传失败1')
                    that.$message.error(file.name + '上传失败')
                  }
                  that.removeOssFilePolicy(file)
                  that.avatarLoading = false
                }
              }
            })
            .catch(err => {
              console.log('失败OSS', policy.host, formData)
              that.fileList.splice(
                that.fileList.findIndex(item => item.name == file.name),
                1
              )
              if (!that.fileList.length) {
                that.loading = false
              }
              that.changeProgress(true)
              console.log('上传失败2')
              that.$message.error(file.name + '上传OSS失败,请查看文件是否重名后联系管理员。')
            })
        })
        .catch(err => {
          this.$message.error('获取OSS签名失败')
        })
    },
    formatDate(datetime) {
      if (datetime != null) {
        const dateMat = new Date(datetime)
        const year = dateMat.getFullYear()
        const month = dateMat.getMonth() + 1
        const day = dateMat.getDate()
        const hh = dateMat.getHours()
        const mm = dateMat.getMinutes()
        const ss = dateMat.getSeconds()
        const timeFormat =
          year +
          '-' +
          (month < 10 ? '0' : '') +
          month +
          '-' +
          (day < 10 ? '0' : '') +
          day +
          ' ' +
          (hh < 10 ? '0' : '') +
          hh +
          ':' +
          (mm < 10 ? '0' : '') +
          mm +
          ':' +
          (ss < 10 ? '0' : '') +
          ss
        return timeFormat
      }
    },
    setUploadProcess(percent, file, index, btnType) {
      /*       Object.assign(file, { percent })
      this.uploadFileList.splice(index, 1, file)
      let num = 0
      this.uploadFileList.forEach(item => {
        num = num + item.percent
      })
      if (btnType == 'original') {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.topProgress = progres
      } else {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.bottomProgress = progres
      } */
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        ///FileReader类就是专门用来读文件的
        const reader = new FileReader()
        //开始读文件
        //readAsDataURL: dataurl它的本质就是图片的二进制数据， 进行base64加密后形成的一个字符串，
        reader.readAsDataURL(file)
        // 成功和失败返回对应的信息，reader.result一个base64，可以直接使用
        reader.onload = () => resolve(reader.result)
        // 失败返回失败的信息
        reader.onerror = error => reject(error)
      })
    }
  }
}
</script>

<style></style>
